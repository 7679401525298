import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';

const initialState = {
    status: StatusAsync.IDLE,
    data: { accountSettings: {}},
    error: null,
};

export const selectDataAccountSettings = (state: any) => state.accountSettings.data;
export const selectAccountSettings = createSelector([selectDataAccountSettings], (data) => (data.accountSettings));

export const accountSettingsReducer = createSlice({
    name: 'accountSettings',
    initialState,
    reducers: {
        setAccountSettings: (state, action) => {
            state.data.accountSettings = action.payload?.accountSettings
        },

    }
});

export const accountSettingsActions = accountSettingsReducer.actions;
export const { setAccountSettings } = accountSettingsReducer.actions;

export default accountSettingsReducer.reducer;
