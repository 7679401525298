import {
    brokerIcon,
    coursesIcon,
    dashIcon,
    shopIcon,
    softwareIcon,
    supportIcon,
} from "../../assets/images/svgIcons/svgIcons";

export const sidebarMenu = [
    {
        id: 1,
        name: 'Dashboard',
        key: 'dashboard',
        to: '/home',
        icon: dashIcon
    },
    {
        id: 2,
        name: 'Mes cours',
        key: 'my-courses',
        to: '/my-courses',
        icon: coursesIcon
    },
    {
        id: 3,
        name: 'Gestion des licences',
        key: 'manage-your-licenses',
        icon: softwareIcon,
        to: '/licences-management',
    },
    {
        id: 5,
        name: 'Choisir un broker',
        key: 'brokers',
        to: '/brokers',
        icon: brokerIcon
    },
    {
        id: 4,
        name: 'Shop',
        key: 'shop',
        to: '/shop',
        icon: shopIcon
    },
    {
        id: 6,
        name: 'Centre d’aide',
        key: 'support',
        to: '/support',
        icon: supportIcon
    },
]