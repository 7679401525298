import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';

const initialState = {
    status: StatusAsync.IDLE,
    data: { courses: [],  currentCourse:null, currentModule:{}},
    error: null,
};

export const selectDataCourses = (state: any) => state.courses.data;
export const selectCourses = createSelector([selectDataCourses], (data) => (data.courses));
export const selectCurrentCourse = createSelector([selectDataCourses], (data) => (data.currentCourse));
export const selectCurrentModule = createSelector([selectDataCourses], (data) => (data.currentModule));

export const coursesReducer = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        setCourses: (state, action) => {
            state.data.courses = action.payload?.courses
        },
        setCurrentCourse: (state, action) => {
            state.data.currentCourse = action.payload?.currentCourse
        },
        setCurrentModule: (state, action) => {
            state.data.currentModule = action.payload?.currentModule
        },

    }
});

export const coursesActions = coursesReducer.actions;
export const { setCourses, setCurrentCourse, setCurrentModule } = coursesReducer.actions;

export default coursesReducer.reducer;
