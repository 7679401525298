import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { Link } from "react-router-dom";

import { menuIcon } from '../../assets/images/svgIcons/svgIcons'
import { Col, Container, Row } from '../../components/core/Container'
import { Picture } from '../../components/core/Picture'
import { selectUserMetadata } from '../../services/redux/reducers/userAuth/user'
import { sidebarMenu } from '../../utils/constants/menu'
import { Button } from '../../components/core/Button'
import { selectMenuDisplay, setMenuDisplay } from '../../services/redux/reducers/home/home'
import ProfileMenu from "../../components/generic/ProfileMenu";
import { auth } from "../../services/firebase/auth/auth";
import { userPic } from "../../services/firebase/user/user";
import { images } from "../../assets/images/images";


const AuthedNavbar: FC = () => {
    const [user] = useAuthState(auth);
    const userMetadata = useSelector(selectUserMetadata)
    const location = useLocation();
    const dispatch = useDispatch()
    const menuStatus = useSelector(selectMenuDisplay)
    const [active, setActive] = useState(null);
    const { courseId } = useParams();
    const modulesPageRegex = useMemo(() => new RegExp('^/my-courses/[0-9]+/*$'), []);
    const lessonPageRegex = useMemo(() => new RegExp('^/my-courses/[0-9]+/[0-9]+/*$'), []);

    const toogleMenu = () => {
        dispatch(setMenuDisplay({ menuDisplay: !menuStatus }))

    }
    const { t } = useTranslation()

    useEffect(() => {
        const loc: any = sidebarMenu.find((e: any) => e.to === location.pathname);
        if (loc?.to) {
            setActive(loc.key);
        }

        if (location.pathname === '/subscription') {
            let a: any = 'subscription'
            setActive(a);
        }

        if (location.pathname === '/account-settings') {
            let a: any = 'accountSettings'
            setActive(a);
        }

    }, [location]);

    const backButton = useCallback(() => {
        if (lessonPageRegex.test(location.pathname)) {
            return (
                <Link to={`/my-courses/${courseId}/`} className='back-button flex aic jcc'>
                    <Picture src={images.arrowBack} alt='go back'></Picture>
                </Link>
            );
        } else if (modulesPageRegex.test(location.pathname)) {
            return (
                <Link to='/my-courses' className='back-button flex aic jcc'>
                    <Picture src={images.arrowBack} alt='go back'></Picture>
                </Link>
            )
        } else {
            return <></>
        }
    }, [lessonPageRegex, location.pathname, modulesPageRegex, courseId]);

    return (
        <Container className='nv-main'>
            <Row className='navbar-main'>
                <Col className='pg-title aifs'>
                    <MediaQuery minWidth={1024}>
                        {active && <h1>{t<any>(`menu.${active}`)}</h1>}
                        {backButton()}
                    </MediaQuery>

                    <MediaQuery maxWidth={1023}>
                        <Button onClick={toogleMenu}>{menuIcon}</Button>
                    </MediaQuery>

                </Col>
                <Row className='nv-profile jcfe'>
                    <Col className='avatar'>
                        <Picture alt='logo' src={userPic(user?.uid, userMetadata)} key={Math.random() * 100} />
                    </Col>
                    <Row className='p-infos aic jcsb'>
                        <ProfileMenu displayName={userMetadata?.DisplayName} key={Math.random() * 100} />
                    </Row>
                </Row>
            </Row>
        </Container>
    )
}

export default AuthedNavbar