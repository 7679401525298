import clsx from "clsx";
import {CSSProperties} from "react";

export const Picture = ({
                            className,
                            src,
                            style,
                            alt = "",
                        }: {
    className?: string;
    alt: string;
    src: string | undefined;
    style?: CSSProperties,
}) => <img className={clsx(className)} src={src} alt={alt} style={style}/>;


export const PictureClickable = ({
                                     className,
                                     src,
                                     alt = "",
                                     onClick
                                 }: {
    className?: string;
    alt: string;
    src: string;
    onClick?: (event: React.MouseEvent) => void;

}) => {
    return <img className={clsx(className)} src={src} alt={alt} onClick={onClick}/>
};