import { selectUser } from "../../services/redux/reducers/userAuth/user";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useAuth = () => {
  const userInfos = useSelector(selectUser);
  const isLoggedIn = useMemo(() => userInfos, [userInfos]);
  return { isLoggedIn };
};


