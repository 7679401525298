
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "../auth/auth";


const functions = getFunctions(app, "europe-west3");
const supportTicketHttpsCallable = httpsCallable(functions, 'createSupportTicket', {});

const supportTimer = "supportTimer"

export const createSupportTicketConstants = {
    sent: 'sent',
    notSent: 'not sent',
    requestDelayInfo: 'Delay info',
};

export async function createSupportTicket({ subject, message, displayName }: { subject: string, message: string, displayName: string }) {

    const createSupportTicketTimer: any = localStorage.getItem(supportTimer) ? new Date(localStorage.getItem(supportTimer) ?? '') : null
    let lastSubmittedTimePlusFiveMinutes: Date = new Date(
        createSupportTicketTimer?.getTime() + 5 * 60000,
    );
    if (
        !createSupportTicketTimer ||
        (createSupportTicketTimer && lastSubmittedTimePlusFiveMinutes < new Date())
    ) {
        let data = {
            subject: subject,
            message: message,
            displayName: displayName
        };
        const result = await supportTicketHttpsCallable(data).then(
            (response: any) => response.data,
            (err: Error) => err,
        );
        if (result?.status === 'Success') {
            let currentDate = new Date();
            localStorage.setItem(supportTimer, currentDate.toString())
            return createSupportTicketConstants.sent;
        } else {
            throw new Error(createSupportTicketConstants.notSent);
        }
    } else {
        throw new Error(createSupportTicketConstants.requestDelayInfo);
    }
}