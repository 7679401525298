import clsx from "clsx";
import React from "react";

export const Button = ({
                           children,
                           className,
                           onClick,
                           disabled = false,
                           type,
                       }: {
    children: any;
    className?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    type?: "button" | "submit" | "reset" | undefined;
}) => (
    <button className={clsx(className)} onClick={onClick} disabled={disabled} type={type}>
        {children}
    </button>
);