import AryaOne from './ar-1.png';
import AryaOneOn from './ar-1-on.svg';
import AryaOnePlusOn from './arya_suite+.svg';
import AryaOneBg from './ar-1-bg.png';
import AryaTwo from './ar-2.png';
import AryaTwoOn from './ar-2-on.png';
import AryaTwoBg from './ar-2-bg.png';
import AryaTwoBgEn from './ar-2-bg-en.png';
import AryaThree from './ar-3.png';
import AryaThreeBg from './ar-3-bg.png';
import AryaThreeBgEn from './ar-3-bg-en.png';
import AryaThreeOn from './ar-3-on.png';
import AryaFour from './ar-4.png';
import AryaFourBg from './ar-4-bg.png';
import AryaFourBgEn from './ar-4-bg-en.png';
import AryaFourOn from './ar-4-on.png';
import AryaFive from './ar-5.png';
import AryaFiveBg from './ar-5-bg.png';
import AryaFiveBgEn from './ar-5-bg-en.png';
import AryaFiveOn from './ar-5-on.png';
import AryaSix from './ar-6.png';
import AryaSixBg from './ar-6-bg.png';
import AryaSixBgEn from './ar-6-bg-en.png';
import AryaSixOn from './ar-6-on.png';
import AryaSeven from './ar-7.png';
import Metatrader from './metatrader.png'
import AryaEssentialPlus from './arya-essential-plus.svg'
import AryaProPlus from './arya-pro-plus.svg'
import AryaTradePlus from './arya-trade-manager-plus.svg'
import AryaSuiteCenturion from './ar-suite-centurion.png'
export const productsPics = {
    AryaOneBg,
    AryaTwoBg,
    AryaTwoBgEn,
    AryaThreeBg,
    AryaThreeBgEn,
    AryaFourBg,
    AryaFourBgEn,
    AryaFiveBg,
    AryaFiveBgEn,
    AryaSixBg,
    AryaSixBgEn,
    AryaFive,
    AryaFiveOn,
    AryaFour,
    AryaFourOn,
    AryaOne,
    AryaOneOn,
    AryaOnePlusOn,
    AryaSix,
    AryaSixOn,
    AryaThree,
    AryaThreeOn,
    AryaTwo,
    AryaTwoOn,
    AryaSeven,
    Metatrader,
    AryaEssentialPlus,
    AryaProPlus,
    AryaTradePlus,
    AryaSuiteCenturion
}