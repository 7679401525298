import { FC, ReactNode } from 'react'
import { Container } from '../components/core/Container'
import AppChecker from './AppChecker'
import AppContainer from './AppContainer'

interface IProps {
    children?: ReactNode
}

export const AppLayout: FC<IProps> = ({ children }) => {


    return (
        <Container>
            <AppChecker>
                <AppContainer>
                    {children}

                </AppContainer>
            </AppChecker>
        </Container>
    )
}

export default AppLayout
