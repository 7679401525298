import {createSlice} from '@reduxjs/toolkit';
//import { STATUS_IDLE, STATUS_PENDING, STATUS_REJECTED, STATUS_RESOLVED } from 'utils/constants';
import {createSelector} from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';

const initialState = {
    status: StatusAsync.IDLE,
    data: {
        user: null,
        authPassword: null,
        token: null,
        userClaims: null,
        customData: null,
        lang: null,
        userMetadata: null,
        userPrivateData: null
    },
    error: null,
};

export const selectDataAuth = (state: any) => state.auth.data;
export const selectIsAuth = createSelector([selectDataAuth], (data) => !!(data.user));
export const selectUser = createSelector([selectDataAuth], (data) => (data.user));
export const selectAuthToken = createSelector([selectDataAuth], (data) => (data.token));
export const selectCustomData = createSelector([selectDataAuth], (data) => (data.customData));
export const selectUserMetadata = createSelector([selectDataAuth], (data) => (data.userMetadata));
export const selectUserPrivateData = createSelector([selectDataAuth], (data) => (data.userPrivateData));

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.status = StatusAsync.PENDING;
        },
        loginSuccess: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.user = action.payload?.user;
        },
        loginDataUpdate: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.user = action.payload?.user;
            state.data.token = action.payload?.token;
        },
        setUserCustomData: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.customData = action.payload?.customData;
        },
        setUserMetadata: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.userMetadata = action.payload?.userMetadata;
        },
        setUserPrivateData: (state, action) => {
            state.status = StatusAsync.RESOLVED;
            state.data.userPrivateData = action.payload?.userPrivateData;
        },

    },
});

export const authActions = authSlice.actions;
export const {
    login,
    loginSuccess,
    loginDataUpdate,
    setUserCustomData,
    setUserMetadata,
    setUserPrivateData
} = authSlice.actions;

export default authSlice.reducer;
