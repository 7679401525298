import { FC, ReactNode } from 'react'

interface IProps {
    children: ReactNode
}

const AuthContainer: FC<IProps> = ({ children }) => {

    return (

        <main className='fdc aic jcc'>
            {children}
        </main>

    )
}

export default AuthContainer