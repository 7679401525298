import firebase from "firebase/compat/app";

export const firebaseConfig = {
  apiKey: "AIzaSyBsvYPxUfROiiGW5RmrxPAt_Lf_IjRwdVA",
  authDomain: "arya-production.firebaseapp.com",
  databaseURL: "https://arya-production.firebaseio.com",
  projectId: "arya-production",
  storageBucket: "arya-production.appspot.com",
  messagingSenderId: "638074715506",
  appId: "1:638074715506:web:9b749993067abe274af1eb",
  measurementId: "G-S3PY7CQ14X"
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

export default firebaseApp


