import React, {FC, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    dropdownIcon,
    metroLinkIcon,
    receiptIcon,
    settingsIcon,
    signOutIcon
} from "../../assets/images/svgIcons/svgIcons";
import {Link} from "react-router-dom";
import {Container, ContainerWithRef, Row} from "../core/Container";
import {Picture} from "../core/Picture";
import {images} from "../../assets/images/images";
import {Button} from "../core/Button";
import {signOutUser} from "../../services/firebase/auth/auth";
import {externalLinks} from "../../utils/constants/urls";

const ProfileMenu: FC<{ displayName: string }> = ({displayName}) => {

    const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);

    const {t, i18n} = useTranslation()

    const ref = useRef<any>(null);

    useEffect(() => {

        // To detect when the user click away and close the dropdown
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowProfileMenu(false);
            }
        }
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [ref]);

    const handleOnAffiliationClick = ()=>{
        if(i18n.language === 'fr'){
            window.open(externalLinks.Affiliation.fr, "_blank");
        }else{
            window.open(externalLinks.Affiliation.en, "_blank");
        }
    };

    return (
        <Button onClick={() => {
            setShowProfileMenu((prevState) => !prevState);
        }} className='flex aic jcc profile-menu-button'>
            <h3 className="display-name">{displayName ? displayName : ''}</h3>
            {dropdownIcon}
            {showProfileMenu &&
                <ContainerWithRef reference={ref} className="fdc profile-menu">
                    <Link to='/account-settings' className="menu-item flex aic jcsb">
                        <Row>
                            <Container className='menu-icon'>{settingsIcon}</Container>
                            <p>{t<any>("profileMenu.accountSettings")}</p>
                        </Row>
                        <div className="dropdown-icon">
                            <Picture alt="arrow-right" src={images.arrowRight}></Picture>
                        </div>
                    </Link>
                    <Link to='/subscription' className="menu-item  flex jcfs aic">
                        <Container className='menu-icon'>{receiptIcon}</Container>
                        <p>{t<any>("profileMenu.ordersAndSubscriptions")}</p>
                    </Link>
                    <Button onClick={handleOnAffiliationClick} className="menu-item flex aic jcfs">
                        <Container className='menu-icon'>{metroLinkIcon}</Container>
                        <p>{t<any>("profileMenu.affiliation")}</p>
                    </Button>
                    <div className="divider"></div>
                    <Button onClick={signOutUser} className="menu-item flex aic jcfs">
                        <Container className='menu-icon'>{signOutIcon}</Container>
                        <p>{t<any>("profileMenu.signOut")}</p>
                    </Button>
                </ContainerWithRef>
            }
        </Button>
    );
}

export default ProfileMenu;