import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export const List = ({
    items,
    className,
    active,
    close
}: {
    items: any;
    className?: string;
    active: string,
    close: any

}) => {
    const navigate = useNavigate()
    const { i18n, t } = useTranslation()

    const onNavigate = (item: any) => {
        if (item.external) {
            const to = item.to;
            if (i18n.language === "fr") {
                window.open(to.fr, "_blank");
            } else {
                window.open(to.en, "_blank");
            }
        } else {
            navigate(item.to);
        }
        close()

    }
    return (
        <ul className={clsx('flex fdc aic jcc', className)}>
            {items.map(((item: any) => {
                let isActive = item.to === active;

                if (active === '' && item.to === '/home') {
                    isActive = true;
                }
                return (
                    <li key={item.id} onClick={() => onNavigate(item)}
                        className={`flex fdr aic jcfs ${isActive && 'active'}`}>
                        <span>{item.icon} </span>
                        <h5>{t(`menu.${item.key}`)}</h5>
                    </li>
                )
            }))}
        </ul>
    )
}