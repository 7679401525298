import firebase from "firebase/compat/app";
import {
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    getAuth,
    GoogleAuthProvider,
    linkWithCredential,
    OAuthProvider,
    PhoneAuthProvider,
    reauthenticateWithCredential,
    RecaptchaVerifier,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    signInWithPopup,
    signOut,
    updatePhoneNumber,
    confirmPasswordReset,
    verifyPasswordResetCode
} from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { firebaseConfig } from "../config";
import { authErrorHandler } from "./helpers";
import { MODE_DEBUG } from "../../../utils/constants/config";
import { toast } from "react-toastify";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";

const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
    "challenges": "{\"unlock_rlt\":{\"coins\":1000},\"unlock_wall_street_2_0\":{\"coins\":1000},\"post_an_idea\":{\"coins\":100},\"invite_10_friends\":{\"coins\":5000},\"invite_25_friends\":{\"coins\":15000},\"invite_50_friends\":{\"coins\":50000}}",
}

fetchAndActivate(remoteConfig)
    .then((fetched) => {
        if (MODE_DEBUG) {
            if (fetched) {
                console.log("Successfully fetched remote config");
            }
        }
    })
    .catch((error) => {
        if (MODE_DEBUG) {
            console.error("Error while fetching remote config");
            console.error(error);
        }
    });


if (MODE_DEBUG) {
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');


declare global {
    interface Window {
        recaptchaVerifier?: any
        confirmationResult?: any
        dataLayer?: any
        _hsq?: any
    }
}


export { auth, db, storage, app }

export const googleAuth = async () => {

    try {
        return await signInWithPopup(auth, provider)
    } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        if (MODE_DEBUG) {
            console.log(errorCode, errorMessage, email, credential)
        }
        throw Error(authErrorHandler(error))
    }
}

export const appleAuth = async () => {
    try {

        const result = await signInWithPopup(auth, appleProvider)
        const user = result.user;
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential?.accessToken;
        const idToken = credential?.idToken;
        if (MODE_DEBUG) {
            console.log(user, accessToken, idToken, credential)
        }
        return result;

    } catch (error: any) {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = OAuthProvider.credentialFromError(error);
        if (MODE_DEBUG) {
            console.log(errorCode, errorMessage, email, credential)
        }
        throw Error(authErrorHandler(error))

    }
}
export const signOutUser = async () => {
    window.location.reload();
    await signOut(auth);
}

export const registerUser = async (user: any) => {
    const { email, password } = user

    try {
        return await createUserWithEmailAndPassword(auth, email, password)

    } catch (error: any) {
        if (MODE_DEBUG) {
            console.log(error.code)
        }
        throw Error(authErrorHandler(error))

    }
}

export const loginUserEmailPassword = async (user: any) => {
    const { email, password } = user

    try {
        return await signInWithEmailAndPassword(auth, email, password)

    } catch (error: any) {
        throw Error(authErrorHandler(error))
    }
}

export const resetPassword = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email)

    } catch (error: any) {
        throw Error(authErrorHandler(error))
    }
}

export const initCaptcha = (element: string) => {
    return window.recaptchaVerifier = new RecaptchaVerifier(element, {
        'size': 'invisible',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        'callback': (response: any) => {
        }
    }, auth);

}

export const verifyPhoneNumber = async (appVerifier: any, phoneNumber: string) => {
    try {
        await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                return confirmationResult
            })
    } catch (error) {
        throw Error(authErrorHandler(error))
    }
}

export const applyPhoneOTP = async (otp: string, user: any, valuePhone: string) => {

    const authCredential = PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        otp,
    );
    const userAuth: any = auth.currentUser
    try {
        await linkWithCredential(userAuth, authCredential)
        await addPhoneNumber(valuePhone)
    } catch (error) {
        if (MODE_DEBUG) {
            console.log(error)
        }
        throw Error(authErrorHandler(error))
    }
}
export const verifyUpdatePhoneNumber = async (appVerifier: any, phoneNumber: string, email: string, password: string) => {

    const userAuth: any = auth.currentUser
    const passwordCredential = EmailAuthProvider.credential(email, password);

    try {
        await reauthenticateWithCredential(userAuth, passwordCredential);
        await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                return confirmationResult
            })
    } catch (error) {
        throw Error(authErrorHandler(error))
    }
}

export const updateNumber = async (otp: string, valuePhone: string) => {
    const authCredential = PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        otp,
    );
    const userAuth: any = auth.currentUser
    try {
        //   await linkWithCredential(userAuth, authCredential)
        await updatePhoneNumber(userAuth, authCredential)
        await addPhoneNumber(valuePhone)
        toast.success('Phone number updated successfully')
    } catch (error) {
        if (MODE_DEBUG) {
            console.log(error)
        }
        throw Error(authErrorHandler(error))
    }
}

export const addPhoneNumber = async (phoneNumber: string) => {
    const userAuth: any = auth.currentUser
    const docRef = doc(db, "users-protected", userAuth?.uid);
    const res = await setDoc(docRef, {
        PhoneNumber: phoneNumber,
    }, {
        merge: true
    })
    console.log(res)
}


export const mapUserInfos = async (uid: string) => {
    const docRef = doc(db, "users", uid,);
    const user = await getDoc(docRef)

    if (user.exists()) {
        if (MODE_DEBUG) console.log("Document data:", user.data());
        return user.data()

    } else {
        console.log("No such document!");
    }
    return user
}

export const ensureUserPictureUrl = async (user: any, userId: string) => {
    if (!user?.PictureDate) return;
    if (user?.PictureDate) {
        const path = `profile-images/${userId}`;
        const storageRef = ref(storage, path);
        const imageUrl = await getDownloadURL(storageRef);
        return imageUrl
    }
}

export const editUserInfo = async (displayName: string, language: string) => {

    const userAuth: any = auth.currentUser
    const docRef = doc(db, "users", userAuth?.uid);
    return await setDoc(docRef, {
        DisplayName: displayName,
        DefaultLanguage: language,
    }, {
        merge: true
    });
}

export const setUserPictureDate = async () => {

    const userAuth: any = auth.currentUser
    const docRef = doc(db, "users", userAuth?.uid);
    return await setDoc(docRef, {
        PictureDate: serverTimestamp(),
    }, {
        merge: true
    });
}


export const getUserPrivateData = async (uid: string) => {
    const docRef = doc(db, "users-private", uid,);
    const userPrivate = await getDoc(docRef)

    if (userPrivate.exists()) {
        if (MODE_DEBUG) console.log("User private data:", userPrivate.data());
        return userPrivate.data()
    } else {
        console.log("No such document!");
    }
    return userPrivate
}

export const isPhoneNumberExists = (userInfos: any, authEmailPass: any, tokens: any) => {
    return userInfos && userInfos?.phoneNumber && !authEmailPass && (tokens?.phone_number || tokens?.passed2FA);
}

export const resetPasswordFromOutsideApp = async (newPassword: string, code: string) => {
    try {
        await confirmPasswordReset(auth, code, newPassword)
    } catch (error: any) {
        throw Error(authErrorHandler(error))
    }
}

export const verifyResetCode = (code: string) => {
    return verifyPasswordResetCode(auth, code);
}