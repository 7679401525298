
export const authErrors: any = {
    // general
    'auth/app-deleted': 'login.error.unknown',
    'auth/app-not-authorized': 'login.error.unknown',
    'auth/argument-error': 'login.error.unknown',
    'auth/invalid-api-key': 'login.error.unknown',
    'auth/invalid-user-token': 'login.error.unknown',
    'auth/invalid-tenant-id': 'login.error.unknown',
    'auth/network-request-failed': 'login.error.networkRequestFailed',
    'auth/operation-not-allowed': 'login.error.operationNotAllowed',
    'auth/requires-recent-login': 'login.error.requiresRecentLogin',
    'auth/too-many-requests': 'login.error.tooManyRequests',
    'auth/unauthorized-domain': 'login.error.unknown',
    'auth/user-token-expired': 'userTokenExpired',
    'auth/web-storage-unsupported': 'login.error.unknown',

    // signin phone
    'auth/missing-phone-number': 'login.error.invalidPhoneNumber',
    'auth/invalid-phone-number': 'login.error.invalidPhoneNumber',
    'auth/captcha-check-failed': 'login.error.captchaCheckFailed',
    'auth/quota-exceeded': 'login.error.quotaExceeded',
    'auth/phone-number-already-exists': 'login.error.credentialAlreadyInUse',
    'auth/account-exists-with-different-credential': 'login.error.credentialAlreadyInUse',
    'auth/code-expired': 'login.error.verificationCodeExpired',

    // linkWithCredential
    'auth/provider-already-linked': 'login.error.providerAlreadyLinked',
    'auth/invalid-credential': 'login.error.invalidCredential',
    'auth/credential-already-in-use': 'login.error.credentialAlreadyInUse',
    'auth/email-already-in-use': 'login.error.emailAlreadyInUse',
    'auth/email-already-exists': 'login.error.emailAlreadyInUse',
    'auth/invalid-verification-id': 'login.error.invalidVerificationId',

    // confirmationResult.confirm
    'auth/missing-verification-code': 'login.error.invalidVerificationCode',
    'auth/invalid-verification-code': 'login.error.invalidVerificationCode',

    //signInWithCustomToken
    'auth/custom-token-mismatch': 'login.error.customTokenMismatch',
    'auth/invalid-custom-token': 'login.error.invalidCustomToken',

    // signInWithEmailAndPassword
    'auth/invalid-email': 'login.error.invalidEmail',
    'auth/user-not-found': 'login.error.badCredentials',
    'auth/wrong-password': 'login.error.badCredentials',
    'auth/user-disabled': 'login.error.disabled',

    // resetPassword
    'auth/expired-action-code': 'login.error.expiredActionCode',
    'auth/invalid-action-code': 'login.error.invalidActionCode',
    'auth/weak-password': 'login.error.weakPassword',
};


export const authErrorHandler = (error: any) => {
    return authErrors[error.code] || 'login.error.unknown';
}

