import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';

const initialState = {
    status: StatusAsync.IDLE,
    data: { invoices: [], subscriptions: [] },
    error: null,
};

export const selectDataSubscriptions = (state: any) => state.subscriptions.data;
export const selectInvoices = createSelector([selectDataSubscriptions], (data) => (data.invoices));
export const selectSubscriptions = createSelector([selectDataSubscriptions], (data) => (data.subscriptions));


export const subscriptionsReducer = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        setInvoices: (state, action) => {
            state.data.invoices = action.payload?.invoices
        },
        setSubscriptions: (state, action) => {
            state.data.subscriptions = action.payload?.subscriptions
        },

    }
});

export const subscriptionsActions = subscriptionsReducer.actions;
export const { setInvoices, setSubscriptions } = subscriptionsReducer.actions;

export default subscriptionsReducer.reducer;
