import { FC, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../components/core/Loading';
import AuthLayout from '../layout/AuthLayout';
import { auth } from '../services/firebase/auth/auth';
import { useAuthState } from "react-firebase-hooks/auth";
import { useAuth } from '../common/hooks/useAuth';
import { MODE_DEBUG } from '../utils/constants/config';
import AppLayout from '../layout/AppLayout';
import { useTranslation } from "react-i18next";
import { GTMEvents } from "../utils/constants/googleTagManager";
import { marketingCampaignURLParam } from "../services/tracking/activeCampaign";
import Support from '../pages/app/Support/Support';

const LoginPage = lazy(() => import('../pages/auth/Login'));
const ForgotPasswordPage = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('../pages/auth/ResetPassword'));
const SignupPage = lazy(() => import('../pages/auth/Signup'));
const HomePage = lazy(() => import('../pages/app/Home/Home'));
const MyCoursesPage = lazy(() => import('../pages/app/Courses/MyCourses'));
const LessonsPage = lazy(() => import('../pages/app/Courses/Lessons'));
const ModulesPage = lazy(() => import('../pages/app/Courses/Modules'));
const BrokersPage = lazy(() => import('../pages/app/Broker'));
const TradingSoftwarePage = lazy(() => import('../pages/app/TradingSoftware'));
const CryptoPlatformPage = lazy(() => import('../pages/app/CyptoPlatform'));
const TradersRoomPage = lazy(() => import('../pages/app/TradersRoom'));
const IdeasPage = lazy(() => import('../pages/app/Ideas'));
const AccountSettingsPage = lazy(() => import('../pages/app/AccountSettings/AccountSettings'));
const SubscriptionsPage = lazy(() => import('../pages/app/Subscription'));
const CampaignPage = lazy(() => import('../pages/app/Campaign/CampaignPage'));
const NotFound = lazy(() => import('../pages/NotFound'));
const ChallengesPage = lazy(() => import('../pages/app/Challenges'));
const ShopPage = lazy(() => import('../pages/app/Shop'));
const CalendarPage = lazy(() => import('../pages/app/Calendar'));

const saveInLocalStorage = (search: string, localStorageVariableName: string) => {
  const urlSearchParams = new URLSearchParams(search);
  if (urlSearchParams.has(localStorageVariableName)) {
    const value = urlSearchParams.get(localStorageVariableName);
    if (value) {
      window.localStorage.setItem(localStorageVariableName, value);
    }
  }
};


const PrivateRoutes = ({ redirectTo = '/login' }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [user, loading,] = useAuthState(auth);


  useEffect(() => {
    window.dataLayer.push({
      event: GTMEvents.pageView,
      pathname,
      search
    });
  }, [pathname, search])

  useEffect(() => {
    saveInLocalStorage(search, marketingCampaignURLParam);
    saveInLocalStorage(search, "tag");
    if (!loading) {
      if (!user) {
        window.localStorage.setItem("redirectAfterLogin", pathname);
        saveInLocalStorage(search, "aryalpcampaign");
        if (pathname.includes('/confirm')) {
          return navigate('signup');
        } else {
          return navigate(redirectTo);
        }
      }
    }

  }, [loading, user, navigate, redirectTo, search, pathname]);

  if (!loading) {
    return (
      <AppLayout>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </AppLayout>
    );
  } else {
    return (
      <AppLayout>
        <Suspense fallback={<LoadingSpinner />} />
      </AppLayout>
    );
  }

}
const PublicRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const { isLoggedIn } = useAuth();
  const { pathname, search } = useLocation();
  const { i18n } = useTranslation();


  useEffect(() => {

    if (loading) {
      if (MODE_DEBUG) {
        console.log('loading')
      }
    }
    saveInLocalStorage(search, marketingCampaignURLParam);
    saveInLocalStorage(search, "tag");
    if (isLoggedIn) {
      const localStorage = window.localStorage;
      const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
      localStorage.removeItem("redirectAfterLogin");
      if (redirectAfterLogin) {
        const aryalpcampaign = localStorage.getItem("aryalpcampaign");
        if (aryalpcampaign) {
          const url = `${redirectAfterLogin}?${new URLSearchParams([["aryalpcampaign", aryalpcampaign]]).toString()}`;
          localStorage.removeItem("aryalpcampaign");
          return navigate(url);
        } else {
          return navigate(redirectAfterLogin);
        }
      } else {
        return navigate('/home');
      }
    } else {
      if (pathname.includes('/en')) {
        i18n.changeLanguage('en');
      } else if (pathname.includes('/fr')) {
        i18n.changeLanguage('fr');
      }
    }

  }, [user, loading, isLoggedIn, location, navigate, i18n, pathname, search]);
  if (!loading) {
    return (

      <AuthLayout>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </AuthLayout>
    );
  } else {
    return (
      <AuthLayout>
        <Suspense fallback={<LoadingSpinner />} />
      </AuthLayout>
    );
  }

};

const Routing: FC = () => (

  <BrowserRouter>
    <Routes>
      <Route path='*' element={<NotFound />} />

      <Route element={<PublicRoutes />}>
        {/* <Route index element={<AuthPage />} /> */}
        <Route path="signup" element={<SignupPage />} />
        <Route path="login" element={<LoginPage />} />

        <Route path="fr" element={<LoginPage />} />
        <Route path="fr/login" element={<LoginPage />} />
        <Route path="fr/signup" element={<SignupPage />} />

        <Route path="en" element={<LoginPage />} />
        <Route path="en/login" element={<LoginPage />} />
        <Route path="en/signup" element={<SignupPage />} />

        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route index element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="my-courses">
          <Route index element={<MyCoursesPage />} />
          <Route path=":courseId/">
            <Route index element={<ModulesPage />} />
            <Route path=":moduleId/" element={<LessonsPage />} />
          </Route>
        </Route>
        <Route path="brokers" element={<BrokersPage />} />
        <Route path="support" element={<Support />} />
        <Route path='licences-management' element={<TradingSoftwarePage />} />
        <Route path="crypto-platform" element={<CryptoPlatformPage />} />
        <Route path="subscription" element={<SubscriptionsPage />} />
        <Route path="traders-room" element={<TradersRoomPage />} />
        <Route path="ideas" element={<IdeasPage />} />
        <Route path="account-settings" element={<AccountSettingsPage />} />
        <Route path="challenges" element={<ChallengesPage />} />
        <Route path="shop" element={<ShopPage />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path=":language/:course/confirm" element={<CampaignPage />} />
      </Route>
    </Routes>
  </BrowserRouter>

);

export default Routing