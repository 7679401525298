import React, { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorBoundary from '../components/ErrorBoundry';
import { AuthListnerInit } from '../services/firebase/auth/auth.services';
import {
  selectUserMetadata
} from '../services/redux/reducers/userAuth/user';
import { useTranslation } from "react-i18next";
import {i18n as dateformatI18n}  from "dateformat"


type IAppProps = {
  children?: ReactNode
}
const AppLayout: FC<IAppProps> = ({ children }) => {
  AuthListnerInit()
  const { i18n } = useTranslation();
  const userMetadata = useSelector(selectUserMetadata);

  useEffect(() => {
    if (userMetadata) {
      if (userMetadata.DefaultLanguage) {
        if (userMetadata.DefaultLanguage === 'en' || userMetadata.DefaultLanguage === 'fr') {
          i18n.changeLanguage(userMetadata.DefaultLanguage);
        }
      }
    }

    if (i18n.language === "fr") {
      dateformatI18n.dayNames = [
        "Dim",
        "Lun",
        "Mar",
        "Mer",
        "Jeu",
        "Ven",
        "Sam",
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
    }

  }, [userMetadata, i18n, i18n.language]);


  return (
    <main className='flex fdc aic jcc'>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </main>
  );
};

export default AppLayout;

