import { FC, ReactNode } from 'react'
import { Container } from '../components/core/Container'
import AppLayout from './AppChecker'
import AuthContainer from './AuthContainer'

interface IProps {
  children?: ReactNode
}

export const AuthLayout: FC<IProps> = ({ children }) => {


  return (
    <Container>
      <AppLayout>
        <AuthContainer>
          <main className='fdc aic jcc'>
            {children}
          </main>
        </AuthContainer>
      </AppLayout>
    </Container>
  )
}

export default AuthLayout
