import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { images } from "../../assets/images/images";
import {
    closePopupIcon,
} from "../../assets/images/svgIcons/svgIcons";
import {
    Col,
    ContainerWithRef,
} from "../../components/core/Container";
import { Picture } from "../../components/core/Picture";
import { List } from "../../components/generic/SidebarMenu";
import {
    selectMenuDisplay,
    setMenuDisplay,
} from "../../services/redux/reducers/home/home";
import { sidebarMenu } from "../../utils/constants/menu";
import { Button } from "../../components/core/Button";
import MediaQuery from "react-responsive";
import clsx from "clsx";
import { useTier } from "../../context/tier.context";

const Sidebar: FC = () => {
    const location = useLocation();
    const [active, setActive] = useState<string>("");
    const dispatch = useDispatch();
    const menuStatus = useSelector(selectMenuDisplay);
    const menuRef = useRef() as RefObject<HTMLDivElement>;
    const { t, i18n } = useTranslation();
    const { isStarter } = useTier();

    const handleClickOutside = useCallback((event: any) => {
        const assetReference =
            menuRef.current && !menuRef.current.contains(event.target);
        if (assetReference && !['Line_6', 'Line_9', 'Line_8', 'Group_13545', 'toggleMenu'].includes(event.target.getAttribute('id'))) {
            dispatch(setMenuDisplay({ menuDisplay: false }));
        }
    }, [dispatch]);

    const closeSideMenu = () => {
        dispatch(setMenuDisplay({ menuDisplay: !menuStatus }));
    };

    useEffect(() => {
        if (sidebarMenu?.length) {
            const activeMenu = sidebarMenu.find((menu) => location.pathname.startsWith(menu.to));
            if (activeMenu?.to) {
                setActive(activeMenu.to);
            }
        }
    }, [location]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <ContainerWithRef reference={menuRef} className={clsx("sd-main", { "sd-opened": menuStatus, "sd-closed": !menuStatus }, "fdc jcfs")}>
            <Col className="sd-logo aifs">
                <Picture alt="logo" src={images.authLogo} />
                <MediaQuery maxWidth={1023}>
                    <span onClick={() => closeSideMenu()} className="close">
                        {closePopupIcon}
                    </span>
                </MediaQuery>
            </Col>
            <List items={sidebarMenu} active={active} close={closeSideMenu} />
            <button className='flex fdc jcc  aic adv ext'
                onClick={() => {
                    window.open(!isStarter ? `https://account.aryatrading.com/${i18n.language}/starter/confirm` : "https://www.aryatrading.com/software/arya-suite-plus", "_blank");
                }}>
                <Picture className="screen-shot" alt="logo" src={!isStarter ? images.SideMenuProBanner : images.SideMenuBanner} />
                {!isStarter ?
                    <div className="banner-content">
                        {i18n.language === "fr" ? (
                            <>
                                <p className="title">Extrayez le meilleur de votre Suite ARYA</p>
                                <p className="description">Live coaching, plans et idées de trading, une communauté active…</p>
                                <Button
                                    className="text-button ad-learn-more-button flex jcc aic"
                                >
                                    REJOINDRE GRATUITEMENT
                                </Button>
                            </>
                        ) : (
                            <>
                                <p className="title">Extract the best from your ARYA Suite</p>
                                <p className="description">Live coaching, trading plans and ideas, an active community…</p>
                                <Button
                                    className="text-button ad-learn-more-button flex jcc aic"
                                >
                                    JOIN FOR FREE
                                </Button>
                            </>
                        )}
                    </div>
                    :
                    <div className="banner-content">
                        {i18n.language === "fr" ? (
                            <>
                                <p className="title">Passez à l’étape supérieure.</p>
                                <p className="description">Débloquez tout le potentiel de votre trading avec ARYA Suite +</p>
                            </>
                        ) : (
                            <>
                                <p className="title">ARYA Starter</p>
                                <p className="description">Free with our broker partner</p>
                            </>
                        )}
                        <Button
                            className="text-button ad-learn-more-button flex jcc aic"
                        >
                            {t<any>("ads.link")}
                        </Button>
                    </div>
                }
            </button>
        </ContainerWithRef>
    );
};

export default Sidebar;
