export const LoadingSpinner = () => (
  <div className="popup-container flex fdc aic jcc">
    <div className="loading-button-overlay flex fdc aic jcc">
      <div className="loading-main-button flex fdc aic jcc" />
    </div>
  </div>
);

export const Spinner = () => (
  <div className="loading-button-overlay flex fdc aic jcc">
    <div className="loading-main-button flex fdc aic jcc" />
  </div>
);
