import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import StatusAsync from '../../../../utils/StatusAsync';

const initialState = {
  status: StatusAsync.IDLE,
  data: { ideaData: null, feedPosts: [], filters: null, menuDisplay: false, licences: null, downloadLinks: null, products: null },
  error: null,
};

export const selectDataHome = (state: any) => state.home.data;
export const selectFeedPosts = createSelector([selectDataHome], (data) => (data.feedPosts));
export const selectFilters = createSelector([selectDataHome], (data) => (data.filters));
export const selectMenuDisplay = createSelector([selectDataHome], (data) => (data.menuDisplay));
export const selectLicencesList = createSelector([selectDataHome], (data) => (data.licences));
export const selectProductsList = createSelector([selectDataHome], (data) => (data.products));
export const selectDonwloadLinks = createSelector([selectDataHome], (data) => (data.downloadLinks));

export const homeReducer = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setPostIdeaData: (state, action) => {
      state.data.ideaData = action.payload?.ideaData
    },
    setFeedPosts: (state, action) => {
      state.data.feedPosts = action.payload?.feedPosts
    },
    setFilters: (state, action) => {
      state.data.filters = action.payload?.filters
    },
    setMenuDisplay: (state, action) => {
      state.data.menuDisplay = action.payload?.menuDisplay
    },
    setLicencesList: (state, action) => {
      state.data.licences = action.payload?.licences
    },
    setDownlaodLinks: (state, action) => {
      state.data.downloadLinks = action.payload?.downloadLinks
    },
    setProductsList: (state, action) => {
      state.data.products = action.payload?.products
    },

  }
});

export const homeActions = homeReducer.actions;
export const { setPostIdeaData, setFeedPosts, setFilters, setMenuDisplay, setLicencesList, setDownlaodLinks, setProductsList } = homeReducer.actions;

export default homeReducer.reducer;
