import { useState, createContext, useContext, useEffect } from "react"
import { getUserTags } from "../services/apis/subscriptions/subscriptionType";
import { selectUser } from "../services/redux/reducers/userAuth/user";
import { useSelector } from "react-redux";

export const TierContext = createContext<any>({ isStarter: undefined, setFinishedAuth: () => false });

export const TierProvider = (props: any) => {
    const [isStarter, setIsStarter] = useState<any>(undefined);
    const [finishedAuth, setFinishedAuth] = useState<boolean>(false);
    const userInfos = useSelector(selectUser);

    useEffect(() => {
        if (userInfos != null && finishedAuth) {
            getUserTags().then(response => {
                const permissions = response.data.permissions.filter((e: any) => e.id.includes("eshop_arya"));
                if (permissions.length > 1) {
                    setIsStarter(false);
                } else if (permissions.length !== 0) {
                    const isStarter = permissions.filter((permission: any) => permission.id.includes('starter'));
                    if (isStarter.length) {
                        setIsStarter(true);
                    } else {
                        setIsStarter(false)
                    }
                } else {
                    setIsStarter(undefined);
                }
            })
        }
    }, [userInfos, finishedAuth])

    const contextValue = { isStarter, setFinishedAuth }

    return (
        <TierContext.Provider value={contextValue}>
            {props.children}
        </TierContext.Provider>
    );
}

export const useTier = () => useContext(TierContext);