export const externalLinks = {
    FAQ: {
        en: "https://help.arya.com",
        fr: "https://help.arya.com",
    },
    shop: {
        en: "https://www.aryatrading.com/",
        fr: "https://fr.aryatrading.com/",
    },
    ADProductLeanMore: {
        en: "https://www.aryatrading.com/software/arya-suite-plus",
        fr: "https://fr.aryatrading.com/logiciels/arya-suite-plus",
    },
    privateCouching: {
        en: "https://checkout.aryatrading.com/en/coaching-session",
        fr: "https://checkout.aryatrading.com/session-coaching-prive",
    },
    bookCouch: {
        en: "https://www.twitch.tv/arya_trading",
        fr: "https://www.twitch.tv/arya_trading",
    },
    userMyServer: {
        en: "https://help.arya.com/en_US/articles/409977-how-do-i-connect-to-my-vps-server",
        fr: "https://help.arya.com/fr/articles/409977-comment-me-connecter-a-mon-serveur-dedie-vps",
    },
    buyAServer: {
        en: "https://checkout.aryatrading.com/en/arya-vps-en",
        fr: "https://checkout.aryatrading.com/vps-arya",
    },
    installAryaSoftware: {
        en: "https://help.arya.com/en_US/articles/409972-arya-software-installation",
        fr: "https://help.arya.com/fr/articles/409972-installer-les-logiciels-arya ",
    },
    ARYAApp: {
        en: "https://app.arya.com/",
        fr: "https://app.arya.com/",
    },
    crypto: {
        fr: {
            login: "https://arya.com/",
            register: "https://accounts.binance.com/fr/register?ref=ED9I4D80",
            deposit: "https://www.binance.com/fr/fiat/deposit/EUR ",
            keys: "https://www.binance.com/fr/",
        },
        en: {
            login: "https://arya.com/",
            register: "https://accounts.binance.com/en/register?ref=ED9I4D80",
            deposit: "https://www.binance.com/en/fiat/deposit/USD ",
            keys: "https://www.binance.com/en/",
        },
    },
    Affiliation: {
        en: "https://partners.greenbull.group/en ",
        fr: "https://partners.greenbull.group/",
    },
    joinTradersRoom: {
        en: "https://www.twitch.tv/arya_trading",
        fr: "https://www.twitch.tv/arya_trading",
    },
    challenges: {
        en: {
            rlt: "https://camp.aryatrading.com/rlt",
            ws: "https://camp.aryatrading.com/ws2-0",
        },
        fr: {
            rlt: "https://camp.aryatrading.com/fr/rlt",
            ws: "https://camp.aryatrading.com/fr/ws2-0",
        }
    },
    termsAndConditions: {
        en: "https://www.aryatrading.com/terms-and-conditions",
        fr: "https://fr.aryatrading.com/cgv"
    },
};
