import React, { Component, ReactNode } from 'react';
import { MODE_DEBUG } from '../utils/constants/config';
import { Button } from './core/Button';
import { Col, Container } from './core/Container';

interface Props {
    children?: ReactNode
}

interface State {
    error: any;
    errorInfo: any
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        // You can also log error messages to an error reporting service here
        console.error('error : ', errorInfo);

        sessionStorage.clear();
        localStorage.clear();

        setTimeout(
            () => {

                window.location.href = "/";
                window.location.reload();
            },
            MODE_DEBUG ? 60000 : 3000
        );
    }

    render() {
        if (this.state?.errorInfo) {
            // Error path
            return (
                <Container className="auth-main">
                    <Container className="auth-container">
                        <Col className="auth-inner">
                            <Col className="form-bloc">
                                <h5 className="error-bloc flex fdc aic jcc" style={{ height: 55, fontSize: 20 }}>
                                    Something went wrong.
                                </h5>

                                <Button
                                    className="email"
                                    onClick={() => {
                                        window.location.href = '/';
                                        window.location.reload();
                                    }}
                                >
                                    Go back to Home
                                </Button>
                                {MODE_DEBUG && (
                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        {this.state.error && this.state.error.toString()}
                                        <br />
                                        {this.state.errorInfo.componentStack}
                                    </details>
                                )}
                            </Col>
                        </Col>
                    </Container>
                </Container>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
