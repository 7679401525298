import { FC, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";

import {
  createSupportTicket,
  createSupportTicketConstants,
} from "../../../services/firebase/support/support";
import { auth } from "../../../services/firebase/auth/auth";
import { Col } from "../../../components/core/Container";
import { Button } from "../../../components/core/Button";
import { Spinner } from "../../../components/core/Loading";
import { images } from "../../../assets/images/images";

import SupportInput, { SupportAreaInput } from "./SupportInput";
import "./Support.scss";

const Support: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const [user] = useAuthState(auth);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("support.form.nameRequired")),
    email: Yup.string()
      .email(t("suppport.form.emailInvalid"))
      .required(t("support.form.emailRequired")),
    message: Yup.string().required(t("support.form.messageRequired")),
  });
  return (
    <div style={{ maxWidth: 1100 }}>
      <div
        className=""
        onClick={() => {
          window.open("https://help.arya.com/fr", "_blank");
        }}
      >
        <img src={images.support_banner_fr} alt="" />
      </div>
      <div className="support-container aifs">
        <Col className="jcfs aifs header-container">
          <p className="title">{t("support.title")}</p>
          <p className="subTitle">{t("support.subTitle")}</p>
        </Col>
        <Formik
          initialValues={{ name: "", email: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (!isSubmitting) {
              setIsSubmitting(true);
              const subject = "Support ticket from Arya dashboard";
              const message = `User info:\nEntered name: ${values.name}\nEntered email: ${values.email}\nSystem name: ${user?.displayName}\nSystem email: ${user?.email}\n\nMessage:\n${values.message}`;
              createSupportTicket({
                subject,
                message,
                displayName: user?.displayName ?? values.name,
              })
                .then(() => {
                  toast.success("Message envoyé avec succès");
                  resetForm();
                })
                .catch((error) => {
                  if (
                    error.message ===
                    createSupportTicketConstants.requestDelayInfo
                  ) {
                    const createSupportTicketTimer: any = localStorage.getItem(
                      "supportTimer"
                    )
                      ? new Date(localStorage.getItem("supportTimer") ?? "")
                      : null;
                    const timeDiff =
                      5 * 60 -
                      moment(new Date()).diff(createSupportTicketTimer) / 1000;
                    const min = Math.floor(timeDiff / 60);
                    const sec = Math.floor(timeDiff % 60);
                    toast.error(
                      `Vous pouvez soumettre une nouvelle demande après ${
                        min ? min + " minutes" : ""
                      } ${sec ? sec + " secondes" : ""}`
                    );
                  } else {
                    toast.error("Quelque chose s'est mal passé.");
                  }
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="form">
              <SupportInput
                name="name"
                title={t("support.form.name")}
                placeholder={t("support.form.name")}
                type="text"
                error={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <SupportInput
                name="email"
                title={t("support.form.email")}
                placeholder={t("support.form.email")}
                type="email"
                error={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <SupportAreaInput
                name="message"
                title={t("support.form.message")}
                placeholder={t("support.form.messagePlaceholder")}
                error={errors.message}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              <Button
                className="jcc submit-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner /> : t("support.form.cta")}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Support;
