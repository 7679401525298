import clsx from "clsx";

import { FC, InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import { Col } from "../../../components/core/Container";

const SupportInput: FC<{ title: string, placeholder: string, type: string, name: string, error?: string } & InputHTMLAttributes<HTMLInputElement>> = ({ title, placeholder, type, name, error, ...props }) => {
    return (
        <Col className="support-input aifs">
            <p className="support-input-label">{title}</p>
            <input name={name} type={type} className={clsx("support-input", { "error": !!error })} placeholder={placeholder ?? ""} {...props} />
            {error && <p className="error-message">{error}</p>}
        </Col>
    )
}

export const SupportAreaInput: FC<{ title: string, placeholder: string, name: string, error?: string } & TextareaHTMLAttributes<HTMLTextAreaElement>> = ({ title, placeholder, name, error, ...props }) => {
    return (
        <Col className="support-input aifs">
            <p className="support-input-label">{title}</p>
            <textarea name={name} rows={10} className={clsx("support-input", { "error": !!error })} placeholder={placeholder ?? ""} {...props} />
            {error && <p className="error-message">{error}</p>}
        </Col>
    )
}


export default SupportInput;