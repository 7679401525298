import axios from "axios"
import { SHOP_API_URL } from "../../../utils/constants/constants";
import { auth } from "../../firebase/auth/auth"

export const getUserTags = async () => {
    const userAuth: any = auth.currentUser
    const userToken: string = await userAuth?.getIdToken();

    return await axios.get(`${SHOP_API_URL}/tag-users/activated`, {
        headers: {
            Authorization: 'Bearer firebase ' + userToken,
            'Content-Type': 'application/json',
        }
    });
};