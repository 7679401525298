import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { images } from "../../../assets/images/images"
import {ref} from "firebase/storage";
import {storage} from "../auth/auth";

export const userPic = (userId?:string, userMetadata?: any) => {
    if(userMetadata){
        if (!userMetadata.PictureDate) return images.aryaLogoShape

        const path = `profile-images/${userId}`;
        const storageRef = ref(storage, path);
        const userPicUrl = `https://firebasestorage.googleapis.com/v0/b/${storageRef.bucket}`;
        return `${userPicUrl}/o/profile-images%2F${userId}?alt=media&&${Date.now()}`
    }else{
        return '';
    }
}

export const unsafeGetLastFeedFetchDateFromDocument = (lastFetchedProtectedDocument: any) => {
    let value = lastFetchedProtectedDocument?.LastFeedFetchDate;
    if (value instanceof Timestamp && value.toDate() < new Date()) {
        return value;
    }
    return Timestamp.fromDate(moment().subtract(1, 'month').toDate());
}