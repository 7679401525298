import clsx from "clsx";

export const Container = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => <div className={clsx('flex aic jcc', className)}>{children}</div>;

export const ContainerWithRef = ({
  children,
  className,
  reference
}: {
  children: any;
  className?: string;
  reference: any,

}) => <div ref={reference} className={clsx('flex aic jcc', className)}>{children}</div>;

export const Wrapper = ({
  children,
  className,
  onClick,
}: {
  children: any;
  className?: string;
  onClick?: any;
}) => (
  <div className={clsx(className)} onClick={onClick}>
    {children}
  </div>
);

export const Row = ({
  children,
  className,
  onClick
}: {
  children: any;
  className?: string;
  onClick?: () => void;
}) => <div className={clsx("flex fdr jcc aic", className)} onClick={onClick}>{children}</div>;

export const Col = ({
  children,
  className,
  onClick
}: {
  children: any;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;

}) => <div className={clsx('flex fdc aic jcc', className)} onClick={onClick}>{children}</div>;

export const Grid = ({
  children,
  className,
  onClick
}: {
  children: any;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;

}) => <div className={clsx('grid aic', className)} onClick={onClick}>{children}</div>;

