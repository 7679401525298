import { onIdTokenChanged } from "firebase/auth";
import { useCallback, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../common/hooks/useAuth";
import { MODE_DEBUG } from "../../../utils/constants/config";
import {
    authActions,
    selectUserMetadata,
    selectUserPrivateData
} from "../../redux/reducers/userAuth/user";

import { auth, getUserPrivateData, mapUserInfos } from "./auth";

export const AuthListnerInit = () => {
    const dispatch = useDispatch();
    const [user, loading, error] = useAuthState(auth);
    const { isLoggedIn } = useAuth()
    const userMetadata = useSelector(selectUserMetadata)
    const userPrivateData = useSelector(selectUserPrivateData)

    const checkClaims = useCallback(async () => {
        const claims = await user?.getIdTokenResult()
        dispatch(authActions.loginDataUpdate({ user, token: claims }));
    }, [user, dispatch]);

    const getUserMetadata = useCallback(
        async () => {
            if (user) {
                const userMetadata = await mapUserInfos(user?.uid)
                dispatch(authActions.setUserMetadata({ userMetadata }));
            }
        },
        [user, dispatch],
    )


    const getUserPrivate = useCallback(async () => {
        if (user) {
            const userPrivateData = await getUserPrivateData(user?.uid)
            dispatch(authActions.setUserPrivateData({ userPrivateData }));
        }
    }, [dispatch, user]);


    useEffect(() => {
        checkClaims()
        dispatch(authActions.loginSuccess({ user }));
        onIdTokenChanged(auth, user => {
            if (user) {
                user.getIdTokenResult().then((async idTokenResult => {
                    dispatch(authActions.loginDataUpdate({ user, token: idTokenResult.claims }));
                    if (MODE_DEBUG) {
                        console.log(idTokenResult)
                        console.log('claims 2FA', idTokenResult.claims.passed2FA)
                        console.log('claims Phone number', idTokenResult.claims.phone_number)
                    }
                }));

            } else {
                if (MODE_DEBUG) {
                    console.log('error')
                }
            }
        })
    }, [user, loading, error, dispatch, checkClaims])

    useEffect(() => {
        if (isLoggedIn && user && !userMetadata) {
            if (MODE_DEBUG) {
                console.log('get user metadata from firestore')
            }
            getUserMetadata()
        }

    }, [isLoggedIn, user, getUserMetadata, userMetadata])

    useEffect(() => {
        if (isLoggedIn && user && !userPrivateData) {
            if (MODE_DEBUG) {
                console.log('get user private data from firestore')
            }
            getUserPrivate();
        }
    }, [isLoggedIn, user, getUserPrivate, userPrivateData])

    return { user, loading, error };
};


