import axios from "axios";
import { ZAPIER_HOOK_URL } from "../../utils/constants/constants";

export const marketingCampaignURLParam = "camp";

export function sendingNewSignupsToZapierHook({ email, firstName, lastName, tag, phoneNumber, firebaseId, language, shopId }: { email: string, firstName: string, lastName: string, tag: string | null, phoneNumber?: string, firebaseId: string, language: string, shopId: number }) {
    return axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
        .post(ZAPIER_HOOK_URL, { email, firstName, lastName, tag, phoneNumber, firebaseId, language, shopId, type: "signup" });
}

export function sendToZapierHookOnLogin({ email, firebaseId, language, shopId }: { email: string, firebaseId: string, language: string, shopId?: number }) {
    return axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
        .post(ZAPIER_HOOK_URL, { email, firebaseId, language, shopId, type: "login" });
}
