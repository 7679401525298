import authIllust from "./auth/auth-1.png";
import authShape from "./auth/auth-shape.png";
import authLogo from "./auth/auth-logo.png";
import authLoginBg from "./auth/auth-login-bg.png";
import userIcon from "./auth/user-icon.png";
import passIcon from "./auth/user-pass.png";
import phoneIcon from "./auth/user-phone.png";
import emailIcon from "./auth/user-email.png";
import googlePlay from "./auth/google-play.svg";
import appleStore from "./auth/appstore.svg";
import defaultAvatar from "./auth/tmp-damir.png";
import awardOne from "./idea/award1.png";
import awardTwo from "./idea/award2.png";
import awardThree from "./idea/award3.png";
import aryaLogoShape from "./auth/arya-logo-shape.png";
import welcomeToTrainingBG from "./courses/welcomeToTrainingBG.svg";
import coachingSessionBG from "./courses/coachingSessionBG.svg";
import congrats from "./courses/congrats.png";
import great from "./courses/great.png";
import good from "./courses/good.png";
import ok from "./courses/ok.png";
import bad from "./courses/bad.png";
import terrible from "./courses/terrible.png";
import downArrow from "./courses/downArrow.png";
import courseSlideShowBG from "./courses/courseSlideShowBG.png";
import dashbaordOne from "./dashboard-one.png";
import laptop from "./laptop.png";
import adv from "./adv.png";
import vps from "./vps.png";
import brokerBg from "./broker-bg.png";
import cryptoBg from "./crypto-bg.png";
import cryptoAsset from "./crypto-asset.png";
import ideas from "./ideas.png";
import close from "./close.png";
import avaTradeS1 from "./campaign/avaTrade-s1.svg";
import avaTradeS2 from "./campaign/avaTrade-s2.svg";
import avaTradeS3 from "./campaign/avaTrade-s3.svg";
import avaTradeS4 from "./campaign/avaTrade-s4.svg";
import avaTradeS5 from "./campaign/avaTrade-s5.svg";
import avaTradeSs from "./campaign/avaTrade-ss.png";
import wallstreetCourseImage from "./campaign/wallstreet-course.png";
import eToroS1 from "./campaign/eToro-s1.png";
import eToroS2 from "./campaign/eToro-s2.png";
import eToroS3 from "./campaign/eToro-s3.png";
import eToroS4 from "./campaign/eToro-s4.svg";
import eToroS5 from "./campaign/eToro-s5.svg";
import eToroSs from "./campaign/eToro-ss.png";
import rtlCourseImage from "./campaign/rtl-course.png";
import admiralMarketsSs from "./campaign/admiralMarkets-ss.webp";
import plusIcon from "./svgIcons/plus-icon.svg";
import phoneUser from "./campaign/phone-user.png";
import phoneUser2 from "./campaign/phone-user-2.png";
import arrowRight from "./arrow-right.png";
import footerLogo from "./campaign/footerLogo.svg";
import adLayer from "./ad-layer.png";
import arrowBack from "./arrowBack.png";
import fabienLg from "./traders-room/fabien-lg.png";
import fabienProfile from "./traders-room/fabien-profile.png";
import lucasLg from "./traders-room/lucas-lg.png";
import lucasProfile from "./traders-room/lucas-profile.png";
import tradersRoomBannerBG from "./traders-room/traders-room-banner-bg.png";
import ideasSS from "./idea/ideas-ss.png";
import appFR from "./idea/arya-app-fr.png";
import appEN from "./idea/arya-app-en.png";
import premiumTraders from "./idea/arya-premium-traders.png";
import richie from "./richie.png";
import rtlChallenge from "./challenges/rlt-challenge.png";
import wsChallenge from "./challenges/ws-challenge.png";
import invite10 from "./challenges/invite10.png";
import invite25 from "./challenges/invite25.png";
import invite50 from "./challenges/invite50.png";
import aryon from "./challenges/aryon.png";
import postAnIdea from "./challenges/postAnIdea.png";
import aryaSuite from "./shop/suite+.png";
import aryaPro from "./shop/pro.png";
import aryaEssential from "./shop/essestial.png";
import aryaTradeManager from "./shop/tradeManager.png";
import aryaCenturion from "./shop/centurion.png";
import aryaCrypto from "./shop/crypto.png";
import aryaCoachingSessionIcon from "./shop/coachingIcon.png";
import aryaAppPremiumIcon from "./shop/aryaAppPremiumIcon.png";
import aryaVPSIcon from "./shop/aryaVPS.png";
import signupFR from "./auth/signup_fr.png";
import signupEN from "./auth/signup_en.png";
import moneyGameBanner from "./idea/money-game-banner.png";
import moneyGameBannerChessBackground from "./idea/money-game-banner-background.png";
import ARYAAppBanner from "./idea/arya-app-banner.png";
import rlt from "./shop/rlt.png";
import scan from "./shop/scan.png";
import ws20 from "./shop/ws20.png";
import unlimitedInvestmentIdeasCourseBG from "./courses/unlimitedInvestmentIdeasCourseBG.png";
import moneyGameCourseBG from "./courses/moneyGameCourseBG.png";
import logicielsCourseBG from "./courses/logicielsCourseBG.png";
import cryptoCourseBG from "./courses/cryptoCourseBG.png";
import RLTRightImage from "./courses/rlt-right-img.png";
import RLTLeftImage from "./courses/rlt-left-img.png";
import RLTMobileImage from "./courses/rlt-mobile-img.png";
import moneyGameChallengeIcon from "./challenges/moneyGameChallenge.png";
import boursePlusChallengeIcon from "./challenges/bourse-plus.png";
import aryaCryptoChallengeIcon from "./challenges/arya-crypto.png";
import aryaSoftwareChallengeIcon from "./challenges/arya-software.png";
import moneyGameShop from "./shop/mg-laptop.png";
import lucasAndFabienCoachesImg from "./coaching/lucas-fabien.png";
import yannImg from "./coaching/yann-coach.png";
import aryaStarterSS from "./campaign/arya-starter-ss.png";
import checkLitewhite from "./campaign/check-Litewhite.svg";
import starterCourseBG from "./courses/starterCourseBG.png";
import starterCourseBGFR from "./courses/starterCourseBGFR.png";
import aryaStarterSmallEN from "./arya_starter_small_EN.png";
import aryaStarterSmallFR from "./arya_starter_small_EN.png";
import discordLogo from "./discord-logo.png";

import QAccess1 from "./quick-access/quick-access-1.png";
import QAccess2 from "./quick-access/quick-access-2.png";
import QAccess3 from "./quick-access/quick-access-3.png";

import SideMenuBanner from "./side-menu-banner.png";
import SideMenuProBanner from "./side-menu-bannar-pro.png";

import FAQIcon from "./faq.png";
import HintIcon from "./hint.png";
import Step4 from "./step4.png";
import aryaStarter from "./shop/starter.png";
import longterm from "./shop/longterm.png";
import combo from "./shop/combo.png";
import ws from "./shop/ws.png";
import support_banner_fr from "./support_banner_fr.png";
import support_banner_en from "./support_banner_en.png";

export const images = {
  authIllust,
  authShape,
  authLogo,
  authLoginBg,
  userIcon,
  passIcon,
  phoneIcon,
  emailIcon,
  googlePlay,
  appleStore,
  defaultAvatar,
  awardOne,
  awardTwo,
  awardThree,
  aryaLogoShape,
  welcomeToTrainingBG,
  coachingSessionBG,
  congrats,
  great,
  good,
  ok,
  bad,
  terrible,
  downArrow,
  courseSlideShowBG,
  dashbaordOne,
  laptop,
  vps,
  adv,
  brokerBg,
  cryptoBg,
  cryptoAsset,
  ideas,
  close,
  arrowRight,
  avaTradeS1,
  avaTradeS2,
  avaTradeS3,
  avaTradeS4,
  avaTradeS5,
  avaTradeSs,
  wallstreetCourseImage,
  eToroS1,
  eToroS2,
  eToroS3,
  eToroS4,
  eToroS5,
  eToroSs,
  rtlCourseImage,
  admiralMarketsSs,
  plusIcon,
  phoneUser,
  phoneUser2,
  footerLogo,
  adLayer,
  arrowBack,
  fabienLg,
  fabienProfile,
  lucasLg,
  lucasProfile,
  tradersRoomBannerBG,
  ideasSS,
  appEN,
  appFR,
  premiumTraders,
  richie,
  rtlChallenge,
  wsChallenge,
  invite10,
  invite25,
  invite50,
  aryon,
  postAnIdea,
  aryaSuite,
  aryaCrypto,
  aryaCenturion,
  aryaAppPremiumIcon,
  aryaCoachingSessionIcon,
  aryaEssential,
  aryaPro,
  aryaTradeManager,
  aryaVPSIcon,
  signupFR,
  signupEN,
  moneyGameBanner,
  moneyGameBannerChessBackground,
  ARYAAppBanner,
  rlt,
  scan,
  ws20,
  unlimitedInvestmentIdeasCourseBG,
  moneyGameCourseBG,
  logicielsCourseBG,
  cryptoCourseBG,
  RLTRightImage,
  RLTLeftImage,
  moneyGameChallengeIcon,
  boursePlusChallengeIcon,
  aryaCryptoChallengeIcon,
  aryaSoftwareChallengeIcon,
  RLTMobileImage,
  moneyGameShop,
  lucasAndFabienCoachesImg,
  yannImg,
  aryaStarterSS,
  checkLitewhite,
  starterCourseBG,
  starterCourseBGFR,
  aryaStarterSmallEN,
  aryaStarterSmallFR,
  discordLogo,

  QAccess1,
  QAccess2,
  QAccess3,
  SideMenuBanner,
  SideMenuProBanner,
  FAQIcon,
  Step4,
  aryaStarter,
  HintIcon,
  combo,
  longterm,
  ws,
  support_banner_fr,
  support_banner_en,
};
