import { productsPics } from "../../assets/images/arya-products";
import { brokerIcons } from "../../assets/images/brokers";
import { images } from "../../assets/images/images";

const userPicUrl = 'https://firebasestorage.googleapis.com/v0/b/arya-9f90f.appspot.com'

const LMS_API_URL = "https://training.aryatrading.com/api";
// const LMS_API_URL = "https://lms-staging.mytechxapp.net/api";
const SHOP_API_URL = 'https://shop-prod.mytechxapp.net'
// const SHOP_API_URL = "https://shop.mytechxapp.net";
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51KeZjcDpPbc5wxXxJ1kF6uObzPA8m6uUQkk02o8568mBTAhVvF3TgLozGEQBJ81e9HXL5nbyoJl7c0KsDToQnSMt00EKlf3INU'
// const STRIPE_PUBLISHABLE_KEY = "pk_test_51KeZjcDpPbc5wxXxoMkbMxRdxj0OQjvrjRaHzEBQG2undYldkQP5St0En0W3BdsCn8bB0uKXDqcY5f2UFG175fYB00Z1zSwc3R";
const ZAPIER_HOOK_URL = 'https://hooks.zapier.com/hooks/catch/2602757/35g6qoz/';

const licenses = [
  "B428a222-d91b-4178-af23-07f23b199ef0",
  "B428a222-d91b-4178-af23-07f23b199ef0",
  "B428a222-d91b-4178-af23-07f23b199ef0",
  "B428a222-d91b-4178-af23-07f23b199ef0",
  "B428a222-d91b-4178-af23-07f23b199ef0",
  "B428a222-d91b-4178-af23-07f23b199ef0",
];
const aryaProducts = [
  { name: "arya", icon: productsPics.AryaFourOn }, // arya is actually pro
  { name: "arya+", icon: productsPics.AryaOnePlusOn },
  { name: "pro", icon: productsPics.AryaOneOn }, // pro is actually arya suit
  { name: "pro+", icon: productsPics.AryaProPlus },
  { name: "scanner", icon: productsPics.AryaSixOn },
  { name: "essential", icon: productsPics.AryaTwoOn },
  { name: "essential+", icon: productsPics.AryaEssentialPlus },
  { name: "trademanager", icon: productsPics.AryaThreeOn },
  { name: "trademanager+", icon: productsPics.AryaTradePlus },
  { name: "centurion", icon: productsPics.AryaFiveOn },
  { name: "pro+_centurion", icon: productsPics.AryaSuiteCenturion },
];

const productsPictures = [
  {
    id: 1,
    on: productsPics.AryaOneOn,
    off: productsPics.AryaOne,
    bg: { en: productsPics.AryaOneBg, fr: productsPics.AryaOneBg },
    key: "arya",
    licenceKey: "arya",
    label: "ARYA",
    learnMoreLink: "",
  },
  {
    id: 2,
    on: productsPics.AryaTwoOn,
    off: productsPics.AryaTwo,
    bg: { en: productsPics.AryaTwoBgEn, fr: productsPics.AryaTwoBg },
    key: "essential",
    licenceKey: "arya-essential",
    label: "ARYA Essential",
    learnMoreLink: "https://www.aryatrading.com/software/arya-essential",
  },
  {
    id: 3,
    on: productsPics.AryaThreeOn,
    off: productsPics.AryaThree,
    bg: { en: productsPics.AryaThreeBgEn, fr: productsPics.AryaThreeBg },
    key: "manager",
    licenceKey: "arya-trade-manager",
    label: "ARYA Trade Manager",
    learnMoreLink: "https://www.aryatrading.com/software/arya-trade-manager",
  },
  {
    id: 4,
    on: productsPics.AryaFourOn,
    off: productsPics.AryaFour,
    bg: { en: productsPics.AryaFourBgEn, fr: productsPics.AryaFourBg },
    key: "pro",
    licenceKey: "arya-pro",
    label: "ARYA Pro",
    learnMoreLink: "https://www.aryatrading.com/software/arya-pro",
  },
  {
    id: 5,
    on: productsPics.AryaFiveOn,
    off: productsPics.AryaFive,
    bg: { en: productsPics.AryaFiveBgEn, fr: productsPics.AryaFiveBg },
    key: "cuntrion",
    licenceKey: "arya-centurion",
    label: "ARYA Centurion",
    learnMoreLink: "https://www.aryatrading.com/software/arya-suite-plus",
  },
  {
    id: 6,
    on: productsPics.AryaSixOn,
    off: productsPics.AryaSix,
    bg: { en: productsPics.AryaSixBgEn, fr: productsPics.AryaSixBg },
    key: "scan",
    licenceKey: "arya-scan",
    label: "ARYA Scan",
    learnMoreLink: "https://www.aryatrading.com/software/arya-scan",
  },
  {
    id: 7,
    on: productsPics.AryaTradePlus,
    off: productsPics.AryaTradePlus,
    bg: { en: productsPics.AryaTwoBgEn, fr: productsPics.AryaTwoBg },
    key: "essential",
    licenceKey: "arya-trade-manager-plus",
    label: "ARYA Essential",
    learnMoreLink: "https://www.aryatrading.com/software/arya-essential",
  },
  {
    id: 8,
    on: productsPics.AryaProPlus,
    off: productsPics.AryaTwo,
    bg: { en: productsPics.AryaTwoBgEn, fr: productsPics.AryaTwoBg },
    key: "essential",
    licenceKey: "arya-pro-plus",
    label: "ARYA Essential",
    learnMoreLink: "https://www.aryatrading.com/software/arya-essential",
  },
  {
    id: 9,
    on: productsPics.AryaEssentialPlus,
    off: productsPics.AryaTwo,
    bg: { en: productsPics.AryaTwoBgEn, fr: productsPics.AryaTwoBg },
    key: "essential",
    licenceKey: "arya-essential-plus",
    label: "ARYA Essential",
    learnMoreLink: "https://www.aryatrading.com/software/arya-essential",
  },
];

//const arya = "Arya";
const essential = "Essential";
const manager = "Manager";
const pro = "Pro";
const centurion = "Centurion";
const scan = "Scan";
const crypto = "Crypto";

export type SoftwareProductsObjectKey = keyof typeof softwareProducts;
const softwareProducts = {
  // [arya]: {
  //   id: 1,
  //   icon: productsPics.AryaOne,
  //   key: "arya",
  //   licenceKey: "arya",
  //   label: "Arya",
  // },
  [essential]: {
    id: 2,
    icon: productsPics.AryaTwo,
    key: "essential",
    licenceKey: "arya-essential",
    label: "ARYA Essential",
  },
  [manager]: {
    id: 3,
    icon: productsPics.AryaThree,
    key: "manager",
    licenceKey: "arya-trade-manager",
    label: "ARYA Trade Manager",
  },
  [pro]: {
    id: 4,
    icon: productsPics.AryaFour,
    key: "pro",
    licenceKey: "arya-pro",
    label: "ARYA Pro",
  },
  [centurion]: {
    id: 5,
    icon: productsPics.AryaFive,
    key: "centurion",
    licenceKey: "arya-centurion",
    label: "ARYA Centurion",
  },
  [scan]: {
    id: 6,
    icon: productsPics.AryaSix,
    key: "scan",
    licenceKey: "arya-scan",
    label: "Scan",
  },
  [crypto]: {
    id: 6,
    icon: productsPics.AryaSeven,
    key: "crypto",
    licenceKey: "arya-crypto",
    label: "ARYA Crypto",
  },
}

const brokers = [
  {
    id: 1,
    icon: brokerIcons.BrokerOne,
    link: "https://www.avatrade.fr/trading-account?tag=90291&ftb=2&pclickId=62b55842141771fef84[…]gnId=164257&campaignName=FR%2520APP%2520Get%2520Started&ftb=2",
    instrumentEn:
      "Shares, CFDs, FX, Cryptos, Commodities, Indices + 2500 assets",
    instrumentFr:
      "Forex, CFDs sur les actions, matières premières, indices et futures. + 2500 instruments",
    avgEn: "Enjoy Wall Street 2.0 training up to 100% free.",
    avgFr: "Finance votre formation Wall Street 2.0 jusqu’à 100%",
    cmp: [
      pro,
      manager,
      essential,
      centurion,
      scan
    ],
  },
  {
    id: 4,
    icon: brokerIcons.BrokerSeven,
    link: "https://cabinet.a-partnership.com/visit/?bta=35122&brand=admiralmarkets",
    instrumentEn:
      "Stocks, CFDs, FX, Cryptos, Commodities, Futures +2000 Assets",
    instrumentFr:
      "Forex, CFDs sur les actions, matières premières, indices et futures. + 1500 instruments",
    avgEn: "Finance your ARYA starter pack",
    avgFr: "Finance votre pack ARYA Starter",
    cmp: [
      pro,
      manager,
      essential,
      centurion,
      scan
    ],
  },
  {
    id: 2,
    icon: brokerIcons.BrokerTwo,
    link: "https://direct.fxpro.com.cy/fr/partner/6014394483",
    instrumentEn:
      "Shares, CFDs, FX, Cryptocurrencies, Commodities, Futures +2500 Assets",
    instrumentFr:
      "Forex, CFDs sur les actions, matières premières, indices et futures. + 2500 instruments",
    avgEn: "Enjoy Wall Street 2.0 training up to 100% free.",
    avgFr: "Finance votre formation Wall Street 2.0 jusqu’à 100%",
    cmp: [
      pro,
      manager,
      essential,
      centurion,
      scan
    ],
  },
  {
    id: 3,
    icon: brokerIcons.BrokerThree,
    link: "https://www.jfdbrokers.com/fr/online-trading/compte/register?account=mod_formpersaccount&ib=95348",
    instrumentEn:
      "Shares, CFDs, FX, Cryptocurrencies, Commodities, Futures +1500 Assets",
    instrumentFr:
      "Forex, CFDs sur les actions, matières premières, indices et futures. + 1500 instruments",
    avgEn: "",
    avgFr: "",
    cmp: [
      pro,
      manager,
      essential,
      centurion,
      scan
    ],
  },
  {
    id: 5,
    icon: brokerIcons.BrokerFor,
    link: "https://accounts.binance.com/fr/register?ref=ED9I4D80",
    instrumentEn: "The world’s largest crypto exchange +350 Cryptocurrencies",
    instrumentFr: "La plus grande plateforme crypto +350 Cryptomonnaies",
    avgEn: "20% discount on the fees",
    avgFr: "Remise de 20% sur les frais",
    cmp: [
      crypto
    ],
  },
  {
    id: 6,
    icon: brokerIcons.BrokerFive,
    link: "https://www.coinbase.com/fr/join/chort_8",
    instrumentEn: "Bitcoin, Ethereum, XRP… +50 cryptocurrencies",
    instrumentFr: "Plateforme de Crypto +100 Cryptomonnaies",
    avgEn: "",
    avgFr: "",
    cmp: [],
  },
  {
    id: 7,
    icon: brokerIcons.BrokerSix,
    link: "https://stocks.etoro.com/fr/commission-free?dl=30002118&utm_medium=Affiliate&utm_sour[…]_serial=FR%20Dashboard&utm_campaign=FR%20Dashboard&utm_term=",
    instrumentEn: "Shares, Commodities, FOREX, Cryptocurrency, ETFs, Indices",
    instrumentFr: "Actions, Forex, matières premières, indices et futures.",
    avgEn: "Enjoy RLT training up to 100% free.",
    avgFr: "Finance votre formation RLT jusqu’à 100%",
    cmp: [],
  },
];

const brokerList = [
  { label: "Admiral Market", value: "Admiral-market" },
  { label: "Directa", value: "Directa" },
  { label: "FX PRO", value: "fx-pro" },
  { label: "Vantage", value: "Vantage" },
  { label: "XTB", value: "XTB" },
  { label: "JFD Brokers", value: "jfd-brokers" },
  { label: "Avatrade", value: "avatrade" },
  { label: "Squared Financial", value: "squared-financial" },
  { label: "Iron FX", value: "iron-fx" },
  { label: "Forex", value: "forex" },
  { label: "Optimus Futures", value: "optimus-futures" },
  { label: "IG", value: "ig" },
  { label: "Other", value: "autres" },
];

export {
  licenses,
  aryaProducts,
  productsPictures,
  brokers,
  brokerList,
  userPicUrl,
  SHOP_API_URL,
  softwareProducts,
  LMS_API_URL,
  STRIPE_PUBLISHABLE_KEY,
  ZAPIER_HOOK_URL
};


export const smallBannerCoursersList = [
  {
    id: 7,
    title: "RLT",
    subTitle: "Start now",
    description: "Long-term Revenue training",
    backgroundColor: "#F7FFEF",
    level: "Beginner",
    durationInHours: 9,
    durationSubtitle: "of training",
  },
  {
    id: 4,
    title: "RLT",
    subTitle: "Commencer",
    description: "Formation Revenus Long-terme",
    backgroundColor: "#F7FFEF",
    level: "Débutant",
    durationInHours: 9,
    durationSubtitle: "de formation"
  },
  {
    id: 34,
    title: "Wall Street 2.0",
    subTitle: "Start now",
    description: "Wall Street 2.0 training",
    backgroundColor: "#EFF6FF",
    level: "Beginner",
    durationInHours: 5,
    durationSubtitle: "of training",
  },
  {
    id: 5,
    title: "Wall Street 2.0",
    subTitle: "Commencer",
    description: "Formation Wall Street 2.0",
    backgroundColor: "#EFF6FF",
    level: "Débutant",
    durationInHours: 5,
    durationSubtitle: "de formation",
  },
  // {
  //     id: 28,
  //     title: "ABC Trading & Investissements",
  //     subTitle: "Accéder aux cours",
  //     description: "ABC Trading & Investissements",
  //     backgroundColor: "#ECF9FF",
  //     level: "Débutant",
  //     durationSubtitle: "Gratuit",
  //     showBigDurationSubtitle: true,
  // },
  // {
  //     id: 29,
  //     title: "ABC Trading & Investments",
  //     subTitle: "Access all courses",
  //     description: "ABC Trading & Investments",
  //     backgroundColor: "#ECF9FF",
  //     level: "Beginner",
  //     durationSubtitle: "Free",
  //     showBigDurationSubtitle: true,
  // },
];



export const extraCourseModulesCards: { [k: string]: { title: string, link: string, image: string, ctaText: string, }[] } = {
  44: [
    {
      title: "Rejoignez le groupe AMO sur Discord",
      link: "https://discord.com/invite/eAFeDqppA2",
      image: images.discordLogo,
      ctaText: "Rejoindre"
    }
  ],
  46: [
    {
      title: "Join AMO group on discord",
      link: "https://discord.com/invite/eAFeDqppA2",
      image: images.discordLogo,
      ctaText: "Join now"
    }
  ]
}