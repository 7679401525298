import { FC, ReactNode } from 'react'
import { Container } from '../components/core/Container'
import MediaQuery from 'react-responsive'

import AuthedNavbar from './Navbar/AuthedNavbar'
import Sidebar from './Sidebar/Sidebar'

interface IProps {
  children: ReactNode
}

const AppContainer: FC<IProps> = ({ children }) => {

  return (
    <Container className='fdr layout aifs'>
      <MediaQuery minWidth={1024}>
        <Sidebar />
      </MediaQuery>

      <MediaQuery maxWidth={1023}>
        <Sidebar />
      </MediaQuery>
      <Container className='fdc aicfs layout-main'>
        <AuthedNavbar />
        <main className='fdc aic jcc app-container-main'>
          {children}
        </main>
      </Container>

    </Container>
  )
}

export default AppContainer